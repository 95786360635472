import { createApp } from 'vue'
import App from './App.vue'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faAnglesRight, faUserGroup, faBed, faBath, faKitchenSet, faArrowRightLong, faBanSmoking, faToilet, faSnowflake, faLocationDot, faBars, faXmark } from '@fortawesome/free-solid-svg-icons'
/* add icons to the library */
library.add(faAnglesRight, faUserGroup, faBed, faBath, faKitchenSet, faArrowRightLong, faBanSmoking, faToilet, faSnowflake, faLocationDot, faBars, faXmark)


/*import the router*/
import router from '@/router'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'


createApp(App).use(Quasar, quasarUserOptions).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app')
