<script>
export default {
    props: {
        title: String,
        uri: String,
    }
}
</script>

<template>
    <div id="link">
        <div>
        </div>
        <h6>
            <router-link :to="uri">{{ title }}</router-link>
        </h6>
    </div>
</template>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');


#link {
    display: flex;
    flex-direction: row;
    padding: 5px;

    div {
        height: 0;
        margin-top: 12px;
        margin-right: 5px;
        border: 2px solid #159A9C;
        width: 5px;
    }

    h6 {
        font-size: 25.92px;
        font-family: 'Roboto', sans-serif;
        text-transform: capitalize;
        color: #FFFFFF;

        a {
            text-decoration: none;
            color: #FFFFFF;
        }
    }
}
</style>