<script>
export default {
    props: {
        title: String,
    }
}
</script>

<template>
    <h6>{{ title }}</h6>
    <div>
    </div>
</template>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Amita:wght@700&display=swap');

h6 {
    font-size: 25.92px;
    font-family: 'Amita', cursive;
    color: #FFFFFF;
}

div {
    margin-top: 4px;
    width: 60px;
    border: 2px solid #159A9C;
}
</style>