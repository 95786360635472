<template>
    <nav>
        <img src="../../assets/logo.png" />
        <div>
            <ul class="menu">
                <li>
                    <router-link to="/">Acceuil</router-link>
                </li>
                <li>

                    <router-link to="/location">Locations</router-link>
                </li>
                <li>
                    <router-link to="/event">Évenements</router-link>
                </li>
                <li>
                    <router-link to="/about">Contact</router-link>
                </li>

            </ul>

        </div>
    </nav>
    <router-view />
</template>

<script>
export default {

}
</script>


<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=KoHo:wght@700&display=swap');

nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 8.3vw;
    height: 100px;
    background: #D9DEB3;

    img {
        height: 92px;
        border-radius: 10px;
    }

    .menu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5vw;

        .router-link-active {
            font-family: 'KoHo', sans-serif;
            font-size: 25.92px;
            color: #3C6125;
            text-decoration: underline;
        }

        a {
            font-family: 'KoHo', sans-serif;
            font-size: 25.92px;
            color: #000000;
            text-decoration: none;
        }



    }

    svg {
        display: none;
    }

    #hamburger {}
}

@media (max-width: 800px) {
    nav {
        svg {
            display: inline-block;
        }

        .menu {
            display: none;
        }

        #hamburger {
            transform: translate(10px);
        }

        #cross {}
    }


}
</style>