<template>
    <div class="LocationCard" :style="style">
        <div class="q-pa-md">

            <q-carousel animated v-model="slide" swipeable control-type="regular" control-color="lime-5"
                control-text-color="lime-1" arrows navigation infinite>
                <q-carousel-slide v-for="slide in slides" :key="slide.id" :name="slide.id" :img-src="slide.url" />
            </q-carousel>
        </div>
        <div class="LocationText">
            <h3>{{ title }}</h3>
            <hr>
            <p>On vous propose une cahabitation chez l'hôte avec une séparation physique des pièces hormis la cuisine
            </p>
            <div id="specificColumn">
                <div class="CardColumn">
                    <p class="ColumnItem">
                        <font-awesome-icon icon="fa-solid fa-user-group" /> 4 MAX
                    </p>
                    <p class="ColumnItem">
                        <font-awesome-icon icon="fa-solid fa-bed" /> 2 chambres lit double
                        <font-awesome-icon icon="fa-solid fa-ban-smoking" />
                    </p>
                    <p class="ColumnItem">
                        <font-awesome-icon icon="fa-solid fa-kitchen-set" /> 1 cuisine complete
                    </p>
                </div>
                <div class="CardColumn">
                    <p class="ColumnItem">
                        <font-awesome-icon icon="fa-solid fa-toilet" />1 toilette
                    </p>
                    <p class="ColumnItem">
                        <font-awesome-icon icon="fa-solid fa-bath" /> 1 salle de bain et d'eau
                    </p>
                    <p class="ColumnItem">
                        <font-awesome-icon icon="fa-solid fa-snowflake" />chambres climatisées
                    </p>
                </div>

            </div>

        </div>
        <router-link to="/location/grande-villa">
            <button v-if="bouton" id="Reserve">Réservez maintenant
                <font-awesome-icon icon="fa-solid fa-arrow-right-long" />
            </button>
        </router-link>
    </div>

</template>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Andika&display=swap');

.LocationCard {
    border-radius: 11px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    border: 1px solid #AAAAAA;

    a {
        text-decoration: none;
    }



    .q-pa-md {
        border-radius: 10px 10px 0px 0px;
        padding: 0;

        .q-carousel {
            border-radius: 10px 10px 0px 0px;

            .q-carousel__slide {
                border-radius: 10px 10px 0px 0px;
            }
        }




    }

    .LocationText {
        padding: 0 20px;

        hr {
            margin: 0px 10px;
        }

        h3 {
            font-family: 'Andika', sans-serif;
            font-size: 42.12px;
        }

        #specificColumn {
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            .CardColumn {
                display: flex;
                flex-direction: column;

                .ColumnItem {
                    display: inline-block;
                }
            }
        }

    }

    #Reserve {
        color: white;
        border-radius: 0px 0px 10px 10px;
        border: none;
        width: 100%;
        padding: 10px 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 25.92px;
        background: #7c736b;
        transition: 0.3s;
    }

    #Reserve:hover {
        color: white;
        border-radius: 0px 0px 10px 10px;
        border: none;
        width: 100%;
        padding: 10px 30px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 25.92px;
        background: #464340;
    }
}
</style>

<script>
import { ref } from 'vue'

export default {
    setup() {
        return {
            slide: ref(1)
        }
    },
    data() {
        return {
        }
    },
    props: {
        slides: Array,
        title: String,
        width: {
            type: String,
            default: "500px",
        },
        bouton: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        style() {
            return 'width: ' + this.width;
        }
    },
}
</script>