<script>
const slidesMariage = [
    { id: 1, url: require('../assets/CardWeeding/1.jpg') },
    { id: 2, url: require('../assets/CardWeeding/2.jpg') },
    { id: 3, url: require('../assets/CardWeeding/3.jpg') },
    { id: 4, url: require('../assets/CardWeeding/4.jpg') },
    { id: 5, url: require('../assets/CardWeeding/5.jpg') },
    { id: 6, url: require('../assets/CardWeeding/6.jpg') },
];
import EventCard from '@/components/EventCard.vue';
export default {
    name: 'EventView',
    components: {
        EventCard,
    },
    data() {
        return {
            slidesMariage
        }
    }
}
</script>
<template>
    <div class="eventDescriptionWrapper">
        <h1>Lieu d'organisation d'évennements à Yaoundé</h1>
        <div class="center">
            <p>Qu’il s’agisse d’un anniversaire d’entreprise, d’une inauguration ou d’un lancement de produit,ou d'une
                simple rencotre entre amis et ou associés la Villa
                Charlestine saura répondre à vos exigences en matière d’organisation d’événements.</p>
        </div>


        <div class="eventDescription left">
            <img src="../assets/EventView/1.png" alt="">
            <div class="content">

                <div class="numberWrapper">
                    <p class="number">01</p>
                    <div class="numberLine"></div>
                </div>
                <h2>où, quand, comment, et surtout pourquoi !</h2>
                <p>La première phase d’un projet événementiel consiste à construire les fondations sur lesquelles
                    s’érigera
                    l’évenement. Il est primordial de réfléchir aux considérations de base qui assureront un fort
                    achalandage à notre événement (choix de la date, choix de la formule, Le nombre de convives, Le
                    budget
                    préliminaire, Le thème, Le mobilier, Le système de son ...).</p>
            </div>
        </div>


        <div class="eventDescription right">
            <div class="content">
                <div class="numberWrapper">
                    <p class="number">02</p>
                    <div class="numberLine"></div>
                </div>
                <h2>analyse des besoins et logistique</h2>
                <p>Puis, l’organisateur est en contact direct avec les fournisseurs. le travail est divisé en 2
                    axes:l’analyse de nos besoins et la logistique de l’événement. le contenu exact de chacune des
                    activités est défini précisément en accord avec le budget. Cela permet de choisir les fournisseurs,
                    et de définir une liste de tâches Précises.</p>
            </div>
            <img src="../assets/EventView/2.png" alt="">
        </div>


        <div class="eventDescription left">
            <img src="../assets/EventView/3.png" alt="">
            <div class="content">

                <div class="numberWrapper">
                    <p class="number">03</p>
                    <div class="numberLine"></div>
                </div>
                <h2>derniers ajustements et répétition </h2>
                <p>Cette phase est la conclusion de notre travail préparatoire. C’est une phase de vérification des
                    informations reçues par les fournisseurs et la finalisation des contrats avec eux directement sur
                    place(permet d’éviter des complications techniques non prévus), les heures précises des différentes
                    phases de l’évenement sont alors définitives ainsi que le contenu de celui-ci.</p>
            </div>
        </div>


        <div class="eventDescription right">

            <div class="content">

                <div class="numberWrapper">
                    <p class="number">04</p>
                    <div class="numberLine"></div>
                </div>
                <h2>le bilan post-évenement</h2>
                <p>Afin de vous permettre et de permettre aux Prochains clients de toujours avoir un service
                    d’excelence, Nous organiserons une réunion de bilan rapide où votre avis sur des points
                    d’amélioration en fonction de votre satisfaction vous seront demandés.</p>
            </div>
            <img src="../assets/EventView/4.png" alt="">
        </div>


    </div>
    <div class="eventList">
        <h2 class="title">Nos derniers évènnements</h2>
        <div class="separator"></div>
        <div class="eventCardWrapper">
            <EventCard :slides="slidesMariage" title="Mariage"></EventCard>
            <EventCard :slides="slidesMariage" title="Mariage"></EventCard>
            <EventCard :slides="slidesMariage" title="Mariage"></EventCard>

        </div>



    </div>
</template>




<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Andika&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bubbler+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amita:wght@700&display=swap');


.eventList {
    margin: 20px 8.3vw;

    .title {
        font-family: 'Bubbler One';
        font-size: 63px;
        text-transform: uppercase;
        text-align: center;

    }

    .separator {
        margin-top: 20px;
        border: 3px solid #DEEFE7;
        width: 400px;
        margin: auto;
        margin-bottom: 20px
    }

    .eventCardWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 50px;

    }

}

.eventDescriptionWrapper {
    background-image: url('../assets/EventView/back.png');
    background-repeat: no-repeat;
    background-size: cover;


    h1 {
        font-family: 'Amita', cursive;
        font-size: 68px;
        padding-top: 20px;
        color: #eeeeee;
        text-align: center;
    }

    .center {
        display: flex;
        justify-content: center;

        p {
            margin-top: 125px;
            margin-bottom: 125px;
            width: 1000px;
            padding: 10px 190px;
            background-color: #0000007c;
            font-family: 'Andika', sans-serif;
            font-size: 28px;
            color: #cac7a8;
            text-align: justify;
            border-radius: 10px 110px;
        }
    }

    .eventDescription {
        margin-top: 100px;
        padding: 10px 8.3vw;
        background: #cac7a8dd;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        img {
            width: 200px;
            height: 200px;
            margin-bottom: 30px;
        }


        .numberWrapper {
            display: inline-block;

            .number {
                font-family: 'Ibarra Real Nova', serif;
                font-size: 110.5px;
                height: 100px;
                line-height: 100px;
                color: #DEEFE7;
                margin: 0;
            }

            .numberLine {
                width: 217px;
                height: 6px;
                background-color: #DEEFE7;
            }
        }

        h2 {
            margin-left: 30px;
            font-size: 42px;
            color: #3C6125;
        }

        p {
            text-align: justify;
            font-family: 'Ibarra Real Nova', serif;
            width: 805px;
            font-size: 28px;
        }
    }

    .left {
        border-radius: 110px 10px;
    }

    .right {
        border-radius: 10px 110px;
    }



}

@media only screen and (max-width: 1300px) {
    img {
        display: none;
    }

    .content {
        margin-left: 0px;
    }
}

@media only screen and (min-width: 1300px) {
    .left {
        .content {
            margin-left: 8.3vw;
        }
    }

    .right {
        .content {
            margin-right: 8.3vw;
        }
    }
}
</style>