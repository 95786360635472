


<script>
import { ref } from 'vue'
export default {
    setup() {
        return {
            slide: ref(1)
        }
    },
    props: {
        slides: Array,
        title: String,
    }
}
</script>


<template>
    <div class="EventCard">
        <div class="q-pa-md">
            <q-carousel animated v-model="slide" swipeable control-type="regular" control-color="lime-6"
                control-text-color="lime-1" arrows navigation infinite>
                <q-carousel-slide v-for="slide in slides" :key="slide.id" :name="slide.id" :img-src="slide.url" />
            </q-carousel>
        </div>
        <div class="EventText">
            <p class="header">~</p>
            <h3 class="header">{{ title }}</h3>
            <p class="header">~</p>
        </div>

    </div>

</template>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Andika&display=swap');

.EventCard {
    width: 500px;
    border-radius: 11px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    border: 1px solid #AAAAAA;



    .q-pa-md {
        border-radius: 10px 10px 0px 0px;
        padding: 0;

        .q-carousel {
            border-radius: 10px 10px 0px 0px;

            .q-carousel__slide {
                border-radius: 10px 10px 0px 0px;
            }
        }




    }

    .EventText {
        text-align: center;

        .header {
            font-family: 'Andika', sans-serif;
            font-size: 42.12px;
            display: inline-block;
        }

        h3 {
            text-decoration-line: underline;
        }
    }
}
</style>