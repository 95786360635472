
<template>
    <h1>Location de Villa à Yaoundé</h1>
    <nav>
        <router-link to="/location/grande-villa">Grande Villa</router-link>
        <div id="navSeparator"></div>
        <router-link to="/location/petite-villa">Petite Villa</router-link>
    </nav>
    <hr>
    <div class="cardContainer">
        <router-view />
    </div>


    <p>Durant votre séjour vous avez également accès à une place de parking couverte. Pour toutes autres précisions sur
        les conditions de séjours ou sur les disponnibilités, n’hésitez pas à le préciser dans le formulaire de contact
        ci-dessous ou à utiliser notre contact directement.</p>

    <div id="formContainer">
        <h2>
            Réserver Votre Villa chez <br><span id="VillaCharlestine">Villa Charlestine</span>
        </h2>
        <form type="POST" action="location.php">
            <fieldset>
                <legend>Identité</legend>
                <label for="start">Arrivée:</label>
                <input type="date" id="start" name="location-start" min="2022-07-10" max="2024-07-10"><br />

                <label for="end">Départ:</label>
                <input type="date" id="end" name="location-end" min="2022-07-10" max="2024-07-10"><br />

                <label for="adult">Adultes</label>
                <input type="number" id="adult" name="number-adult" value="1" min="1" max="4"
                    style="width : 50px;"><br />

                <label for="children">Enfants</label>
                <input type="number" id="children" name="number-children" value="0" min="0" max="3"
                    style="width : 50px;">




            </fieldset>
            <fieldset>
                <legend>Moyen de contact</legend>
                <div id="margin-left">
                    <input type="text" name="name" placeholder="Nom" /><br />
                    <input type="text" name="surname" placeholder="Prénom" /><br />
                    <input type="tel" name="Téléphone" placeholder="063664510659" /><br />
                    <input type="text" name="address" placeholder="Adresse" /><br />
                    <input type="email" name="email" placeholder="Email" />
                </div>

            </fieldset>

            <fieldset style="width : 100%;">
                <div style="display : flex; flex-direction: column; justify-content: center;">
                    <label for="message">Mettez ci-dessous toutes les informations complémentaires</label><br />
                    <textarea name=" Message " id=" Message " rows="10" cols="50"></textarea><br />


                    <div style="text-align: center;">
                        <button type="submit">Envoyer</button>
                    </div>


                </div>
            </fieldset>



        </form>

    </div>
</template>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Bubbler+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Square+Peg&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amita:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

h1 {
    font-family: 'Amita', cursive;
    font-size: 68px;
    text-align: center;
}

.cardContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

#formContainer {
    margin: 0px 8.3vw;
    width: auto;
    height: auto;
    background: #CAC7A8;
    color: white;

    h2 {
        text-align: center;
        font-family: 'Amita', cursive;
        font-size: 42;

        #VillaCharlestine {
            font-family: 'Square Peg', cursive;
        }
    }

    form {
        font-family: 'Lato', sans-serif;
        margin: 0px 8.3vw;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;


        fieldset {
            width: 50%;

            #margin-left {
                margin-left: 50px;
            }

            legend {
                font-size: 42px;
                text-decoration: underline;
            }

            label {
                font-size: 26px;
                margin-left: 50px;
            }
        }

    }

}

nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0px 8.3vw;

    #navSeparator {
        width: 1px;
        height: 50px;
        background: black;
        display: inline-block;
    }

    a {
        font-family: 'Bubbler One', sans-serif;
        font-size: 68.23px;
        color: #000000;
        text-decoration: none;
    }

    .router-link-exact-active {
        color: #C4CD8B;
    }
}

hr {
    margin: 0px 8.3vw;
    margin-bottom: 20px;
}
</style>

<script>
export default {
    components: {
    }
}
</script>