<template>
    <DesktopNav v-if="!mobileView" />
    <MobileNav v-else />
</template>

<script>
import DesktopNav from '../components/navbar/DesktopNav.vue';
import MobileNav from '../components/navbar/MobileNav.vue';
export default {
    name: 'NavBar',
    components: {
        DesktopNav,
        MobileNav

    }
    , data() {
        return {
            mobileView: false,
        }
    },
    methods: {
        handleView() {
            this.mobileView = window.innerWidth <= 820;
        }
    },
    created() {
        this.handleView();
        window.addEventListener('resize', this.handleView);
    },
}
</script>


<style lang="scss" scoped>
</style>