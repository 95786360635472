<template>
    <div id="header">
        <div id="FirstLine">
            <img src="../assets/About/pp.png" alt="" id="Pp">
            <div id="TextHeader">
                <p id="FirstText">Une question?</p>
                <p id="SecondText">Rejoignez la Villa Charlestine!</p>
            </div>
        </div>
        <div id="Separator"></div>
    </div>
    <p id="FormText">Laissez-nous un message avec le plus de détails possible. Nous ne manquerons pas de revenir vers
        vous rapidement.
    </p>

    <form type="POST" action="contact.php">
        <fieldset>
            <legend>Identité & Moyen de contact</legend>

            <input type="text" name="name" placeholder="Nom" />
            <input type="text" name="surname" placeholder="Prénom" />
            <input type="email" name="email" placeholder="Email" />
            <input type="tel" name="Téléphone" placeholder="063664510659" />
            <input type="text" name="address" placeholder="Adresse" />

            <legend>Message</legend>
            <input type="text" name="object" placeholder="Objet" />
            <input type="text" name="content" placeholder="Message" />





        </fieldset>



        <button type="submit">Submit!</button>


    </form>
</template>



<script>

</script>



<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Amita:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Andika&display=swap');

#header {
    margin: 30px 8.3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;

    #FirstLine {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-bottom: 30px;


        img {}

        #TextHeader {

            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            #FirstText {
                margin-top: 30px;
                font-family: 'Amita', cursive;
                font-size: 26px;
            }

            #SecondText {
                font-family: 'Amita', cursive;
                font-size: 42px;
                color: #B1BC64;
            }
        }

    }

    #Separator {
        padding-right: 40px;
        margin: auto;
        width: 750px;
        height: 10px;
        background-color: #D9DEB3;
    }


}

#FormText {
    font-family: 'Andika', sans-serif;
    font-size: 26px;
    margin: 0 8.3vw;
    text-align: center;
}

#Masque {
    z-index: -1000;
}
</style>