<template>
    <p>Comment Wrapper(utiliser vue horizontal??)</p>
</template>

<script>
export default {
    name: 'CommentWrapper',
    components: {
    }
}
</script>

<style lang="scss" scoped>
p {
    font-family: 'Courier New', Courier, monospace;
}
</style>