<script>
import FooterTitle from '../components/footer/FooterTitle.vue'
import FooterLink from '@/components/footer/footerLink.vue'
export default {
    components: {
        FooterTitle,
        FooterLink
    }
}
</script>

<template>
    <div id="imgWrapper">
        <img src="../assets/logo.png" id="logo" />
        <div class="iconWrapper">
            <div class="iconAndText">
                <img src="../assets/icon/whatsapp-brands.svg" alt="" class="icon" />
                <p>+237 6 78 14 11 05 </p>
            </div>
            <div class="iconAndText">
                <img src="../assets/icon/orange-money-brands.png" alt="" class="icon" />
                <p>+237 6 90 66 04 08 </p>
            </div>
            <div class="iconAndText">
                <font-awesome-icon icon="fa-solid fa-location-dot" class="icon" />
                <p>Yaoundé, Odza Lycée de Minkan</p>
            </div>
        </div>


    </div>

    <footer>
        <div id="footerWrapper">
            <div class="footerFlexItem">
                <FooterTitle title="Navigation" />
                <FooterLink title="Accueil" uri="/" />
                <FooterLink title="Location" uri="/location/grande-villa" />
                <FooterLink title="Évenementiel" uri="/event" />
                <FooterLink title="Contact" uri="/about" />
            </div>
            <div class="footerFlexItem">
                <FooterTitle title="Villa Charlestine" />
                <p>La villa charlestine est une entreprise familiale fondée par Chalestine Lhommée</p>
            </div>
            <div class="footerFlexItem">
                <FooterTitle title="Localisation" />
                <p>LYCEE DE MINKAN, YAOUNDE ODZA</p>
            </div>
        </div>
        <p class="copyright">© Villa charlestine entreprise 2022 - Mise à jour : 20/06/2022</p>
        <p class="copyright">Réalisé par LHOMMEE Jocelyn(Etudiant en ingénierie informatique spécialisé en Cloud à
            l’INSA de Rennes)</p>
    </footer>
</template>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@700&display=swap');

#imgWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


    .iconWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 200px;

        .iconAndText {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

        }

        p {
            margin: 0;
        }
    }

    #logo {
        width: 250px;
    }

    .icon {
        width: 30px;
        height: 30px;
        margin-right: 10px
    }
}


footer {
    background: #211914;
    padding: 20px 8.3vw;

    #footerWrapper {


        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;

        .footerFlexItem {
            display: inline-block;
            padding: 0 10px;
            max-width: 500px;

            p {
                font-family: 'Source Sans Pro', sans-serif;
                font-size: 25.92px;
                color: white;
            }
        }
    }

    .copyright {
        text-align: center;
        color: white;
    }
}
</style>