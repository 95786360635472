import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/HomeView.vue';
import Location from '@/views/LocationView.vue';
import Event from '@/views/EventView.vue';
import About from '@/views/AboutView.vue';
import LocationCard from '@/components/LocationCard.vue';

const slidesGV = [
    { id: 1, url: require('../assets/CardGV/1.jpg') },
    { id: 2, url: require('../assets/CardGV/2.jpg') },
    { id: 3, url: require('../assets/CardGV/3.jpg') },
    { id: 4, url: require('../assets/CardGV/4.jpg') },
];
const slidesPV = [
    { id: 1, url: require('../assets/CardGV/4.jpg') },
    { id: 2, url: require('../assets/CardGV/3.jpg') },
    { id: 3, url: require('../assets/CardGV/2.jpg') },
    { id: 4, url: require('../assets/CardGV/1.jpg') },
];


const routes = [
    {
        path: '/',
        component: Home,
    }, {
        path: '/location',
        component: Location,
        redirect: to => {
            if (to.path === '/location') {
                return { path: '/location/grande-villa' }
            }
        },
        children: [
            {
                path: 'grande-villa',
                component: LocationCard,
                props: { width: "1000px", title: 'Grande Villa', bouton: false, slides: slidesGV }
            },
            {
                path: 'petite-villa',
                component: LocationCard,
                props: { width: "1000px", title: 'Petite Villa', bouton: false, slides: slidesPV }
            }
        ]
    }, {
        path: '/event',
        component: Event,
    }, {
        path: '/about',
        component: About,
    }
]
const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
}
)

export default router;