<template>
    <h1>Villa Charlestine</h1>
    <img src="../assets/facadeGV.png" />
    <CommentWrapper />
    <div id="textInformation">
        <p>“La villa Charlestine vous souhaite la bienvenue,
            on vous y invite afin d’y séjourner, d’organiser vos évenements de touts types, On vous propose également un
            service Traiteur qui livre dans Yaoundé a partir de 10 plats ou lors de vos receptions”
        </p>
    </div>
    <div class="offer">
        <h2>Offre de location</h2>
        <div class="cardContainer">
            <LocationCard title="Grande Villa" :slides="slidesGV"></LocationCard>
            <LocationCard title="Petite Villa" :slides="slidesPV"></LocationCard>
        </div>


    </div>
    <div class="offer" id="events">
        <h2>Organisation d'évennementiels</h2>
        <div class="cardContainer">
            <EventCard :slides="slidesMariage" title="Mariage"></EventCard>
            <EventCard :slides="slidesMariage" title="Anniversaire"></EventCard>
        </div>
        <div id="buttonContainer">
            <router-link to="/event">
                <bouton id="eventButton">
                    <span id="textContainer">
                        <span id="text1">Et bien plus ...</span>
                        <span id="text2">
                            <span>Découvrez notre offre</span>
                            <font-awesome-icon icon="fa-solid fa-angles-right" />
                        </span>
                    </span>
                </bouton>
            </router-link>
        </div>
    </div>
</template>

<script>
const slidesGV = [
    { id: 1, url: require('../assets/CardGV/1.jpg') },
    { id: 2, url: require('../assets/CardGV/2.jpg') },
    { id: 3, url: require('../assets/CardGV/3.jpg') },
    { id: 4, url: require('../assets/CardGV/4.jpg') },
];
const slidesPV = [
    { id: 1, url: require('../assets/CardGV/4.jpg') },
    { id: 2, url: require('../assets/CardGV/3.jpg') },
    { id: 3, url: require('../assets/CardGV/2.jpg') },
    { id: 4, url: require('../assets/CardGV/1.jpg') },
];
const slidesMariage = [
    { id: 1, url: require('../assets/CardWeeding/1.jpg') },
    { id: 2, url: require('../assets/CardWeeding/2.jpg') },
    { id: 3, url: require('../assets/CardWeeding/3.jpg') },
    { id: 4, url: require('../assets/CardWeeding/4.jpg') },
    { id: 5, url: require('../assets/CardWeeding/5.jpg') },
    { id: 6, url: require('../assets/CardWeeding/6.jpg') },
];
import CommentWrapper from '../components/CommentWrapper.vue';
import LocationCard from '@/components/LocationCard.vue';
import EventCard from '@/components/EventCard.vue';
export default {
    name: 'HomeView',
    components: {
        CommentWrapper,
        LocationCard,
        EventCard,
    },
    data() {
        return {
            slidesGV,
            slidesPV,
            slidesMariage
        }
    }
}
</script>


<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Square+Peg&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Andika&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bubbler+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');

h1 {
    left: 12.5vw;
    margin: 0;
    position: absolute;
    top: calc(9vw + 30px);
    font-family: 'Square Peg', cursive;
    font-size: 110px;
}

img {
    width: 100%;
}

@media only screen and (max-width: 700px) {

    h1 {
        top: calc(9vw + 40px);
        font-size: 68px;
    }
}


#textInformation {
    justify-content: center;
    padding: 15px 8.3vw;
    background: rgba(167, 204, 120, 0.56);

    p {
        font-family: 'Andika', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 25.92px;
        line-height: 42px;
        text-align: center;
    }
}

.offer {
    padding: 15px 8.3vw;

    .cardContainer {
        margin: 20px 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 15px;
    }

    h2 {
        font-family: 'Bubbler One', sans-serif;
        font-style: normal;
        font-weight: 100;
        font-size: 68.23px;
        text-transform: uppercase;
        color: #3C6125;
    }

    #buttonContainer {
        text-align: center;

        #eventButton {
            transition: 0.3s;
            padding: 20px;
            border-radius: 30px;
            display: inline-block;
            border: 1px solid rgb(60, 97, 37);
            box-shadow: 0 1px 4px rgba(60, 97, 37, 0.3);
            color: #3C6125;

            #textContainer {
                display: flex;
                flex-direction: column;

                #text1 {
                    font-family: 'Chakra Petch', sans-serif;
                    font-size: 66.6144px;

                }

                #text2 {
                    font-family: 'Cairo', sans-serif;
                    font-size: 36px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    svg {
                        transition: 0.3s;
                    }
                }
            }
        }

        #eventButton:hover {
            background: #3C6125;
            padding: 20px;
            border-radius: 30px;
            display: inline-block;
            border: 1px solid #ffffff;
            box-shadow: 0 1px 4px #ffffff4d;
            color: #ffffff;

            #textContainer {
                display: flex;
                flex-direction: column;

                #text1 {
                    font-family: 'Chakra Petch', sans-serif;
                    font-size: 66.6144px;

                }

                #text2 {
                    font-family: 'Cairo', sans-serif;
                    font-size: 36px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    svg {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}

#events {
    background: rgba(202, 199, 168, 0.72);
}
</style>