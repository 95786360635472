<template>
    <nav>

        <div class="visible">
            <img src="../../assets/logo.png" />
            <div class="navIcon">
                <font-awesome-icon icon="fa-solid fa-bars" id='hamburger' v-if="!showMenu"
                    @click="showMenu = !showMenu" />
                <font-awesome-icon icon="fa-solid fa-xmark" id='cross' v-else @click="showMenu = !showMenu" />
            </div>


        </div>
        <ul class="menu" :class="{ 'open': showMenu }">
            <li>
                <router-link to="/" @click="showMenu = !showMenu">Acceuil</router-link>
            </li>
            <li>

                <router-link to="/location" @click="showMenu = !showMenu">Locations</router-link>
            </li>
            <li>
                <router-link to="/event" @click="showMenu = !showMenu">Évenements</router-link>
            </li>
            <li>
                <router-link to="/about" @click="showMenu = !showMenu">Contact</router-link>
            </li>

        </ul>
    </nav>
    <router-view />
</template>

<script>
export default {
    components: {

    },
    data() {
        return {
            showMenu: false,
        }
    }
}
</script>


<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=KoHo:wght@700&display=swap');

nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    height: 95px;
    background: #D9DEB3;

    .visible {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 8.3vw;

        img {
            height: 92px;
            border-radius: 10px;
        }

        .navIcon {
            svg {}

            #hamburger {}
        }


    }



    .menu {
        transform: translateX(-900px);
        z-index: 100;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;
        transition: 1s transform cubic-bezier(0, .12, .14, 1);
        background: #D9DEB3;

        .router-link-active {
            font-family: 'KoHo', sans-serif;
            font-size: 25.92px;
            color: #3C6125;
            text-decoration: underline;
        }

        a {
            font-family: 'KoHo', sans-serif;
            font-size: 25.92px;
            color: #000000;
            text-decoration: none;
        }


    }

    .open {
        transform: translateX(0px);
    }

}
</style>